<template>
    <div class="container">
        <div class="col-12 py-4">
            <div class="card">
                <DataTable 
                    :value="countries" 
                    :loading="loading" 
                    responsive-layout="scroll" 
                    showGridlines 
                    :paginator="true"

                    :rows="rowsPagValue" 
                    :rowsPerPageOptions="[10,20,50]" 
                    :rowHover="true" 
                    :first="firstPagValue"
                    
                    removableSort 
                    dataKey="id"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                    v-model:filters="filters"
                    @row-click="open" 
                    filterDisplay="menu"
                    :sort-field="sortFieldValue"
                    :sort-order="sortOrderValue"
                    @sort="onSort($event)"
                    @page="onSort($event)"
                >
                    <template #header>
                        <h5 class="mb-2 md:m-0 p-as-md-center">Countries</h5>
                        <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                            <!-- add component -->
                            <div class="col-sm-12 col-md-2 d-flex flex-row -justify-content-md-start">
                                <!-- <CreateCountry /> -->
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="d-flex flex-row justify-content-md-end">
                                    <Button 
                                        type="button" 
                                        icon="pi pi-filter-slash" 
                                        label="Clear"
                                        class="p-button-outlined me-4" 
                                        @click="clearFilter()" 
                                    />
                                    <span class="p-input-icon-left" style="position: relative;">
                                        <i class="pi pi-search"  style="position: absolute; left: 5%; top:50%" />
                                        <InputText 
                                            v-model="filters['global'].value" 
                                            placeholder="Keyword Search" 
                                            class="keywordSearch"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <Column 
                        v-for="col of columns" 
                        :field="col.field" 
                        :header="col.header" 
                        :key="col.field" 
                        :sortable="true"
                    >
                    </Column>

                    <Column 
                        :field="'countryCode'"
                        :header="'CountryCode'"
                        :key="'countryCode'"
                        :sortable="true"
                    >
                    </Column>

                    <template #paginatorstart>
                        <Button type="button" icon="pi pi-refresh" class="p-button-text" @click="getRegions(lastFilter)" />
                    </template>
                </DataTable>
            </div>
        </div>
    </div>

</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    name: 'CountryList',
    components: {
        DataTable,
        Column,
        Button,
        InputText,
    },
    data() {
        return {
            loading: true,
            createTopicIsLoading: false,
            // look
            editTopicIsLoading: false,
            deleteTopicIsLoading: false,
            filters: null,
            lastFilter: null,
            //sort
            sortFieldValue: null,
            sortOrderValue: null,
            //paginationCountValues
            firstPagValue : null,
            rowsPagValue: 50,
        };
    },
    created() {
        this.columns = [
            { field: 'name', header: 'Name' },
        ];
        this.initFilters();
    },
    async mounted() {
        await this.getCountries();
        this.setAdminCountrySort();
    },
    computed: {
        countries() {
            return this.$store.getters.obtainCountries;
        },
        adminCountrySort(){
            return this.$store.getters.obtainAdminCountrySort;
        }
    },
    methods: {
        // create Store of countries
        async getCountries() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCountries');
                this.loading = false;
            }
            catch (error) {
                toast.error(error.message);
                this.loading = false;
            }
        },
        setAdminCountrySort(){
            try {
                //sort
                this.sortFieldValue = this.adminCountrySort.sortField;
                this.sortOrderValue = this.adminCountrySort.sortOrder;
                
                //pag
                if(this.adminCountrySort.firstPagValue === null){
                    this.firstPagValue = 0;
                }
                else{
                    this.firstPagValue = this.adminCountrySort.firstPagValue;
                }
                if(this.adminCountrySort.rowsPagValue === null){
                    this.rowsPagValue = 50;
                }
                else{
                    this.rowsPagValue = this.adminCountrySort.rowsPagValue
                }
            } catch (error) {
                console.log(error);
            }
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        open(event) {
            this.$router.push(`./countries/country/${event.data.countryId}`);
        },
        onSort(e){
            const adminCountrySort = {
                sortField: e.sortField,
                sortOrder: e.sortOrder,
                firstPagValue: e.first,
                rowsPagValue: e.rows
            }
            this.$store.dispatch('updateAdminCountrySort', adminCountrySort) 
        }
    },
    watch: {
        selectedCountryTypeId(newVal) {
            this.lastFilter = newVal;
            this.getCountries(newVal);
        }
    }
};
</script>

<style scoped>
.keywordSearch{
  /* padding: 0 0 0 2.5rem  !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>